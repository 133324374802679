import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../App.css";
import axios from "axios";
import swal from "sweetalert";
import ItemDetails from "./ItemDetails";
import { useNavigate } from "react-router-dom";
// import Medicine from './Medicine';
// import Fmcg from './Fmcg';
// import Oprs from './Oprs';
// import Surgical from './Surgical';
import { useSession } from "./../Common/SessionContext";
import {
  validateMedicine,
  validateFmcg,
  validateOprs,
  validateSurgical,
} from "../Common/validationFunctions";

function ProductDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { request } = location.state || {};
  console.log(request);
  const { sessionData } = useSession();
  console.log(sessionData);
  const serverUrl = process.env.REACT_APP_API_URL;

  const [errors, setErrors] = useState({});
  const [rejectionReason, setRejectionReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [similarItems, setSimilarItems] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [formData, setFormData] = useState({
    /* [c_barcode,c_cont_code,c_createuser,c_disease_cat_code,
        c_modiuser,c_pack_type_code,c_sh_name,c_storage_care_code,
        c_storage_code,n_exp_dt_rule,n_hsn_sac_flag,n_mrp_box,n_self_barcode_req,n_service_item,ismolecule]*/

    // branchCode:request.branch_code,
    // Email:request.user_information.store_email,
    // Mobileno:request.user_information.s_mobile_no,
    // suppliername:request.user_information.s_name,
    // ContactName:request.user_information.store_contact_no,

    name: request.product_information.name || request.product_name || "", //c_name
    fullName: request.product_information.fullName || "", //c_full_name
    brandName: request.product_information.brandName || "", //c_brand_code
    brandCode: request.product_information.brandCode || "", //c_brand_name
    strength: request.product_information.strength || "", //c_strength
    qtyPerPack: request.product_information.qtyPerPack || "", //n_qty_per_box
    mrpPerPack: request.product_information.mrpPerPack || "", //n_mrp_box
    puRatePerPack: request.product_information.puRatePerPack || "",
    shelfLifeDays: request.product_information.shelfLifeDays || "", //n_shelf_life
    marketedBy: request.product_information.marketedBy || "", //c_mfac_code
    main_category: request.main_category || "", //sessionData.category
    category: request.product_information.category
      ? JSON.parse(request.product_information.category)
      : {} || {}, //c_cat_code
    // head: request.product_information.head || '',
    head: request.product_information.head,
    headName: request.product_information.headName,
    class: request.product_information.class,
    className: request.product_information.className,

    classType: request.product_information.classType || "",
    pack: request.product_information.pack || "", //c_pack_type_code
    gst: request.product_information.gst || "", //c_gst_code\
    margin: request.product_information.margin || "", //margin added by rakesh
    schedule: request.product_information.schedule || "", //c_schedule_code
    storage: request.product_information.storage || "",
    hsnSacNo: request.product_information.hsnSacNo || "", //c_hsn_sac_code
    itemCohort: request.product_information.itemCohort || "", //c_item_type_code
    publish: request.product_information.publish || "",
    sellingType: request.product_information.sellingType || "",
    returnType: request.product_information.returnType || "", //n_non_returnable_item
    discount: request.product_information.discount || "", //n_discount_rate
    mfgDate: request.product_information.mfgDate || "",
    expDate: request.product_information.expDate || "",
    note: request.product_information.note || "", //c_note
    Dosage: request.product_information.Dosage || "", //c_pack_type_code

    Eancode: request.product_information.Eancode || "",
    Barcode: request.product_information.Barcode || "",

    discountSlab: request.product_information.discountSlab || "",
    allowDiscount: request.product_information.allowDiscount || "",
    groupCode: request.product_information.groupCode || "", //c_group_code
    PublishOnApp: request.product_information.PublishOnApp || "",
    molecule: request.product_information.molecule
      ? JSON.parse(request.product_information.molecule)
      : {} || {},
    request_id: request.req_id,

    placementform: request.product_information.placementform || "",
    storagecare: request.product_information.storagecare || "",
    universal_key: request.product_information.universal_key || "",
    universal_detail: request.product_information.universal_detail || "",
  });

  const handleChange = async (e) => {
    const { id, value } = e.target;
    console.log(id, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    if (id === "fullName" && value.trim() !== "") {
      try {
        const response = await axios.get(
          `${serverUrl}/api/similar-items?name=${value}`
        );
        setSimilarItems(response.data);
        setDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching similar items:", error);
        setSimilarItems([]);
        setDropdownOpen(false);
      }
    } else if (id === "placementform") {
      console.log(value);
      setLoading(true);
      await axios
        .get(
          `${serverUrl}/api/universal-batch?iname=${formData.name}&sfrom=${value}`
        )
        .then((response) => {
          console.log(JSON.stringify(response.data));
          console.log(response.data.body.assigned_location);
          console.log(response.data.statusCode);
          setLoading(false);
          setFormData((prev) => ({
            ...prev,
            universal_key: response.data.body.assigned_location || "-", // Keep margin to 2 decimal places
            universal_detail:
              response.data.statusCode == 200
                ? JSON.stringify(response.data.body)
                : "" || "",
          }));
        })
        .catch((error) => {
          console.log(error);
        });
      // const response = await axios.get('http://n0k4otf42g.execute-api.ap-south-1.amazonaws.com/dev/npi', {
      //   params: {
      //     item_name: 'SEMENTO 10CAP ##',
      //     storage_form:'Tab Drawer'
      //   }
      // });
      // console.log(response)
      // universal_key
    } else if (id === "puRatePerPack" || id === "mrpPerPack" || id === "gst") {
      calculateMargin({
        ...formData,
        [id]: value,
      });
    } else {
      setSimilarItems([]);
      setDropdownOpen(false);
    }
    // const { id, value } = e.target;
    // console.log(value);
    // if(id==="category"){
    //     const fetchedHead = value ? 'Head based on category' : '';
    //     const fetchedClass = value ? 'Class based on category' : '';

    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         category: value,
    //         head: fetchedHead,
    //         class: fetchedClass
    //     }));
    // } else {
    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [id]: value
    //     }));
    // }
  };

  const calculateMargin = ({ puRatePerPack, mrpPerPack, gst }) => {
    const puRate = parseFloat(puRatePerPack) || 0;
    const mrp = parseFloat(mrpPerPack) || 0;
    const gstValue = parseFloat(gst.match(/\d+/)) || 0;

    console.log("puRate", puRate);
    console.log("mrp", mrp);
    console.log("gst", gstValue);

    if (mrp > 0) {
      const margin = ((mrp - (puRate + (puRate * gstValue) / 100)) / mrp) * 100;

      setFormData((prev) => ({
        ...prev,
        margin: margin.toFixed(2), // Keep margin to 2 decimal places
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        margin: "",
      }));
    }
  };
  const handleChangeRequestCategory = async (e) => {
    const { id, value } = e.target;
    console.log(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    // const apiUrl = "${serverUrl}/api/changeRequestCategory/"+request.req_id;

    //         const response = await axios.post(apiUrl, formDataObject);
    //         swal({
    //             title: "Success!",
    //             text: response.message,
    //             icon: "success",
    //             button: "OK",
    //         });

    //         if (sessionData.userRole === 'admin') {
    //             navigate('/admin/list');
    //           }
    //            else if (sessionData.userRole === 'spoc') {
    //             navigate('/spoc/list');
    //           }
  };
  const handleCategoryChange = (selectedItem) => {
    console.log(selectedItem);
    if (selectedItem) {
      setFormData({
        ...formData,

        headName: selectedItem.l2_name,
        head: selectedItem.l2_code,

        class: selectedItem.l1_code,
        className: selectedItem.l1_name,

        category: JSON.stringify(selectedItem),
      });
    } else {
      setFormData({
        ...formData,
        category: "",
        head: "",
        headName: "Head",
        class: "",
        className: "Class",
      });
    }
  };

  const validate = () => {
    const validatefields = [
      "name",
      "fullName",
      "brandName",
      "marketedBy",
      "puRatePerPack",
      "pack",
      "qtyPerPack",
      "mrpPerPack",
      "storage",
      "sellingType",
      "returnType",
      "mfgDate",
      "expDate",
      "shelfLifeDays",
      "hsnSacNo",
      "category",
      "gst",
      "groupCode",
      "PublishOnApp",
      "itemCohort",
    ];
    let newErrors = {};
    for (let key in formData) {
      if (!formData[key] && validatefields.includes(key)) {
        newErrors[key] = "This field is required";
      }
    }
    // switch (formData.main_category) {
    //     case '2':
    //         console.log(validateMedicine)
    //         newErrors = { ...newErrors, ...validateMedicine(formData) };
    //         break;
    //     case '3':
    //     case '4':
    //         newErrors = { ...newErrors, ...validateFmcg(formData) };
    //         break;
    //     case '5':
    //         newErrors = { ...newErrors, ...validateOprs(formData) };
    //         break;
    //     case '1':
    //         newErrors = { ...newErrors, ...validateSurgical(formData) };
    //         break;
    //     default:
    //         break;
    // }
    setErrors(newErrors);
    console.log(Object.keys(newErrors));
    return Object.keys(newErrors).length === 0;
  };

  function setActionAndSubmit(event) {
    const action = event.target.getAttribute("data-action");
    document.getElementById("action").value = action;
    handleSubmit(event);
  }

  // const setActionAndSubmit = (event, selectedCategory) => {
  //     setFormData(prevState => ({
  //         ...prevState,
  //         main_category: selectedCategory
  //     }));
  //     handleSubmit(event, false);
  // };

  const confirmCategoryChange = async (e) => {
    const selectedCategory = e.target.value;

    const confirmChange = await swal({
      title: "Are you sure?",
      text: "Do you want to change the category? This will submit the form.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
        
    if (confirmChange) {
      updateCategory(selectedCategory);
    } else {
      e.target.value = formData.main_category;
    }
  };

  const updateCategory = async (selectedCategory) => {
    try {
      const response = await axios.post(
        `${serverUrl}/api/update-category/${formData.request_id}`,
        {
          main_category: selectedCategory,
        }
      );

      console.log(response, "updateCategory");

      if (response.data.status) {
        swal({
          title: "Success!",
          text: response.data.message,
          icon: "success",
          button: "OK",
        });
        setFormData((prevState) => ({
          ...prevState,
          main_category: selectedCategory,
        }));
        if (sessionData.userRole === "admin") {
          navigate("/admin/list");
        } else if (sessionData.userRole === "spoc") {
          navigate("/spoc/list");
        }
      } else {
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error",
          button: "OK",
        });
      }
    } catch (error) {
      swal({
        title: "Error!",
        text: "An error occurred while updating the category.",
        icon: "error",
        button: "OK",
      });
      console.error("Error updating category:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    const apiUrl = serverUrl + "/api/submit/" + formData.request_id;
    console.log("ApiUrl", apiUrl);

    setLoading(true);
    const action = document.getElementById("action").value;

    try {
      if (!validate() && action === "verifyAndApprove") {
        return;
      }
      if (action === "reject") {
        const reason = await swal({
          title: "Reason for Rejection",
          text: "Please provide the reason for rejection",
          content: "input",
          button: {
            text: "Submit",
            closeModal: false,
          },
        });

        if (!reason) {
          swal("You need to provide a reason for rejection.", {
            icon: "error",
          });
          return;
        }

        setRejectionReason(reason);
        formData.rejectionReason = reason;
      }

      const submissionData = new FormData();
      let newErrors = {};
      for (const key in formData) {
        console.log(formData[key]);
        // if (formData[key] == "") {
        //     newErrors[key] = 'This field is required';
        //     setErrors(newErrors);
        //     console.log(newErrors)
        // }
        if (formData[key] !== null) {
          if (key == "category") {
            console.log(typeof formData[key]);
            if (typeof formData[key] == "string") {
              submissionData.append(key, formData[key]);
            } else {
              submissionData.append(key, JSON.stringify(formData[key]));
            }
          } else if (key == "molecule") {
            console.log(formData[key]);
            if (typeof formData[key] == "string") {
              submissionData.append(key, formData[key]);
            } else {
              submissionData.append(key, JSON.stringify(formData[key]));
            }
          } else if (key == "main_category") {
            const categorychanged =
              document.getElementById("main_category").value;
            submissionData.append(key, categorychanged);
          } else {
            submissionData.append(key, formData[key]);
          }
        } else {
          console.log("hello");
          newErrors[key] = "This field is required";
        }
      }

      if (true) {
        submissionData.append("action", action);

        const formDataObject = Object.fromEntries(submissionData.entries());

        console.log("FormData as Object:", formDataObject);

        const apiUrl = serverUrl + "/api/submit/" + formData.request_id;
        console.log("ApiUrl", apiUrl);

        const response = await axios.post(apiUrl, formDataObject);

        if (response.data.status) {
          swal({
            title: "Success!",
            text: response.data.message,
            icon: "success",
            button: "OK",
          });
          if (sessionData.userRole === "admin") {
            navigate("/admin/list");
          } else if (sessionData.userRole === "spoc") {
            navigate("/spoc/list");
          }
        } else {
          swal({
            title: "Error!",
            text: response.data.message,
            icon: "error", // Corrected the icon type
            button: "OK",
          });

          // if (sessionData.userRole === 'admin') {
          //     navigate('/admin/list');
          // }
          // else if (sessionData.userRole === 'spoc') {
          //     navigate('/spoc/list');
          // }
        }
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
      swal({
        title: "Error!",
        text: "An error occurred while submitting the form.",
        icon: "error",
        button: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Call calculateMargin when puRatePerPack, mrpPerPack, or gst changes
    if (
      formData.puRatePerPack &&
      formData.mrpPerPack &&
      formData.gst !== undefined
    ) {
      calculateMargin(formData);
    }
  }, [formData.puRatePerPack, formData.mrpPerPack, formData.gst]);

  useEffect(() => {
    if (formData.mfgDate && formData.expDate) {
      const mfgDate = new Date(formData.mfgDate);
      const expDate = new Date(formData.expDate);
      const shelfLife = Math.ceil((expDate - mfgDate) / (1000 * 60 * 60 * 24)); // Calculate the difference in days

      setFormData((prevData) => ({
        ...prevData,
        shelfLifeDays: shelfLife >= 0 ? shelfLife : "Invalid Dates",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        shelfLifeDays: "",
      }));
    }
  }, [formData.mfgDate, formData.expDate]);

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="container-fluid ">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <form id="NPIdetail" method="POST" onSubmit={handleSubmit}>
              <div className="row mt-4">
                <h2>New Product Creation</h2>
                {sessionData.userRole !== "qc" && (
                  <div className="row mb-2 mt-5">
                    <div className="col-md-3">
                      <div className="form-group">
                        <div class="input-group mb-3">
                          <label
                            className="d-inline-block form-label"
                            Style="margin-right: 10px;"
                          >
                            Reassign To
                          </label>
                          {/* <label htmlFor="main_category" className="mb-2">Main Category <span>*</span></label> */}
                          <select
                            className="ml-3 form-select capital"
                            id="main_category"
                            value={formData.main_category}
                            data-action="categoryChange"
                            onChange={confirmCategoryChange}
                          >
                            <option value="">Select Category</option>
                            <option value="1">SURGICAL</option>
                            <option value="2">MEDICINE</option>
                            <option value="3">FMCG FOOD</option>
                            <option value="4">FMCG NON FOOD</option>
                            <option value="5">OPRS</option>
                          </select>
                        </div>

                        {errors.main_category && (
                          <span className="error-message text-danger">
                            {errors.main_category}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <ItemDetails
                  formData={formData}
                  setFormData={setFormData}
                  handleChange={handleChange}
                  handleCategoryChange={handleCategoryChange}
                  errors={errors}
                  request={request}
                  isMedicine={request.main_category === "2"}
                  isFmcg={
                    request.main_category === "3" ||
                    request.main_category === "4"
                  }
                  isSurgical={request.main_category === "1"}
                  isOPRS={request.main_category === "5"}
                  similarItems={similarItems}
                  setSimilarItems={setSimilarItems}
                  dropdownOpen={dropdownOpen}
                  setDropdownOpen={setDropdownOpen}
                  sessionData={sessionData}
                />
                {/* <hr style={{ backgroundColor: 'black' }} /> */}
                <input type="hidden" id="action" name="action" value="" />
                <input
                  type="hidden"
                  id="universal_detail"
                  name="universal_detail"
                  value={formData.universal_detail}
                />

                {sessionData.userRole === "spoc" &&
                  request.status == "Pending" && (
                    <>
                      <button
                        type="button"
                        data-action="verifyAndApprove"
                        onClick={setActionAndSubmit}
                        className="btn btn-info"
                        style={{ width: "120px", marginRight: "10px" }}
                      >
                        Verify
                      </button>
                      <button
                        type="button"
                        data-action="reject"
                        onClick={setActionAndSubmit}
                        className="btn btn-danger"
                        style={{ width: "120px", marginRight: "10px" }}
                      >
                        Reject
                      </button>

                      {/* <button className="btn btn-success" style={{ width: '120px' }}>Post</button> */}
                    </>
                  )}

                {sessionData.userRole === "admin" &&
                  request.status == "Verified" && (
                    <>
                      <button
                        type="button"
                        data-action="verifyAndApprove"
                        onClick={setActionAndSubmit}
                        className="btn btn-success"
                        style={{ width: "120px", marginRight: "10px" }}
                      >
                        Approve
                      </button>
                      <button
                        type="button"
                        data-action="reject"
                        onClick={setActionAndSubmit}
                        className="btn btn-danger"
                        style={{ width: "120px" }}
                      >
                        Reject
                      </button>
                    </>
                  )}

                {sessionData.userRole === "user" && (
                  <button
                    className="btn btn-primary"
                    style={{ width: "120px" }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>

            {/* <div className="card card1 custom-card shadow p-2 bg-body rounded">
                            <div className="card-body">
                                <h5 className="card-header text-center h2">Global Form</h5>

                            </div>
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
