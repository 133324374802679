import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../App.css";
import axios from "axios";
import swal from "sweetalert";
import ItemDetails from "./ItemDetails";
import { useNavigate } from "react-router-dom";
import Autocomplete from "../Common/AutoComplete";
import InnerImageZoom from "react-inner-image-zoom";
// import Medicine from './Medicine';
// import Fmcg from './Fmcg';
// import Oprs from './Oprs';
// import Surgical from './Surgical';
import { useSession } from "./../Common/SessionContext";
import {
  validateMedicine,
  validateFmcg,
  validateOprs,
  validateSurgical,
} from "../Common/validationFunctions";

function QcProductDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { request } = location.state || {};
  // console.log(request);
  const { sessionData } = useSession();
  // console.log(sessionData);
  const serverUrl = process.env.REACT_APP_API_URL;
  const isReadOnly =
    request.status === "Rejected" || sessionData.userRole === "qc";
  const [errors, setErrors] = useState({});
  const [rejectionReason, setRejectionReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [similarItems, setSimilarItems] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const initialImages = {
    invoiceImage: {
      url: request.media.invoiceImage,
      downloadUrl: request.media.invoiceImage,
    },
    frontImage: {
      url: request.media.frontImage,
      downloadUrl: request.media.frontImage,
    },
    backImage: {
      url: request.media.backImage,
      downloadUrl: request.media.backImage,
    },
    leftImage: {
      url: request.media.leftImage,
      downloadUrl: request.media.leftImage,
    },
    rightImage: {
      url: request.media.rightImage,
      downloadUrl: request.media.rightImage,
    },
  };

  const [images, setImages] = useState(initialImages);

  const requiredKeys = [
    "fullName",
    "pack",
    "Dosage",
    "className",
    "headName",
    "schedule",
    "storage",
  ];
  // const [formdataarray, setFormdataarray] = useState(request.product_information);
  const [formData, setFormData] = useState(request.product_information);
  const [formdataarray, setFormdataarray] = useState(
    requiredKeys.map((key) => ({
      name: key,
      value:
        key in request.product_information
          ? typeof request.product_information[key] === "object"
            ? JSON.stringify(request.product_information[key])
            : request.product_information[key]
          : "N/A", // Default if key doesn't exist
    }))
  );

  console.log("formdataarray--->", formdataarray);

  console.log(
    "formdataarray product_information--->",
    request.product_information
  );

  const handleChange = async (e) => {
    const { id, value } = e.target;
    //console.log(id, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    if (id === "fullName" && value.trim() !== "") {
      try {
        const response = await axios.get(
          `${serverUrl}/api/similar-items?name=${value}`
        );
        setSimilarItems(response.data);
        setDropdownOpen(true);
      } catch (error) {
        console.error("Error fetching similar items:", error);
        setSimilarItems([]);
        setDropdownOpen(false);
      }
    } else if (id === "placementform") {
      console.log(value);
      setLoading(true);
      await axios
        .get(
          `${serverUrl}/api/universal-batch?iname=${formData.name}&sfrom=${value}`
        )
        .then((response) => {
          // console.log(JSON.stringify(response.data));
          // console.log(response.data.body.assigned_location);
          // console.log(response.data.statusCode);
          setLoading(false);
          setFormData((prev) => ({
            ...prev,
            universal_key: response.data.body.assigned_location || "-", // Keep margin to 2 decimal places
            universal_detail:
              response.data.statusCode == 200
                ? JSON.stringify(response.data.body)
                : "" || "",
          }));
        })
        .catch((error) => {
          console.log(error);
        });
      // const response = await axios.get('http://n0k4otf42g.execute-api.ap-south-1.amazonaws.com/dev/npi', {
      //   params: {
      //     item_name: 'SEMENTO 10CAP ##',
      //     storage_form:'Tab Drawer'
      //   }
      // });
      // console.log(response)
      // universal_key
    } else if (id === "puRatePerPack" || id === "mrpPerPack" || id === "gst") {
    } else {
      setSimilarItems([]);
      setDropdownOpen(false);
    }
    // const { id, value } = e.target;
    // console.log(value);
    // if(id==="category"){
    //     const fetchedHead = value ? 'Head based on category' : '';
    //     const fetchedClass = value ? 'Class based on category' : '';

    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         category: value,
    //         head: fetchedHead,
    //         class: fetchedClass
    //     }));
    // } else {
    //     setFormData((prevFormData) => ({
    //         ...prevFormData,
    //         [id]: value
    //     }));
    // }
  };

  const validate = () => {
    const validatefields = [
      "name",
      // "fullName",
      // "brandName",
      // "marketedBy",
      // "puRatePerPack",
      // "pack",
      // "qtyPerPack",
      // "mrpPerPack",
      "storage",
      // "sellingType",
      // "returnType",
      // "mfgDate",
      // "expDate",
      // "shelfLifeDays",
      // "hsnSacNo",
      // "category",
      // "gst",
      // "groupCode",
      // "PublishOnApp",
      // "itemCohort",
    ];
    let newErrors = {};
    for (let key in formData) {
      if (!formData[key] && validatefields.includes(key)) {
        newErrors[key] = "This field is required";
      }
    }

    setErrors(newErrors);
    console.log(Object.keys(newErrors));
    return Object.keys(newErrors).length === 0;
  };

  // const setActionAndSubmit = (event, selectedCategory) => {
  //     setFormData(prevState => ({
  //         ...prevState,
  //         main_category: selectedCategory
  //     }));
  //     handleSubmit(event, false);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    const apiUrl = serverUrl + "/api1/submit/" + formData.request_id;
    console.log("ApiUrl----->", apiUrl);

    setLoading(true);
    // const action = document.getElementById("action").value;

    try {
      // if (!validate() && action === "verifyAndApprove") {
      //   return;
      // }

      const submissionData = new FormData();
      let newErrors = {};
      for (const key in formData) {
        console.log(formData[key]);
        submissionData.append(key, formData[key]);
      }

      const apiUrl = serverUrl + "/api/Qcsubmit/" + formData.request_id;
      console.log("ApiUrl", apiUrl);

      // submissionData.append("action", action);

      const formDataObject = Object.fromEntries(submissionData.entries());

      console.log("FormData as Object:", formDataObject);

      const response = await axios.post(apiUrl, formDataObject);

      if (response.data.status) {
        swal({
          title: "Success!",
          text: response.data.message,
          icon: "success",
          button: "OK",
        });
        if (sessionData.userRole === "admin") {
          navigate("/admin/list");
        } else if (sessionData.userRole === "spoc") {
          navigate("/spoc/list");
        } else if (sessionData.userRole === "qc") {
          navigate("/qc/list");
        }
      } else {
        swal({
          title: "Error!",
          text: response.data.message,
          icon: "error", // Corrected the icon type
          button: "OK",
        });

        // if (sessionData.userRole === 'admin') {
        //     navigate('/admin/list');
        // }
        // else if (sessionData.userRole === 'spoc') {
        //     navigate('/spoc/list');
        // }
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
      swal({
        title: "Error!",
        text: "An error occurred while submitting the form.",
        icon: "error",
        button: "OK",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-4">
            {/* <form id="NPIdetail" method="POST" onSubmit={handleSubmit}> */}
            <div className="card row mt-4">
              <div className="card-head p-3">
                <h2>QC Product Creation</h2>
              </div>
              {formdataarray.length > 0 && (
                <div
                  className="border p-2"
                  Style="max-height:80vh;overflow-y:scroll;overflow-x: hidden;"
                >
                  {/* <h3 className="font-bold mb-2">Submitted Data</h3> */}
                  <Table data={formdataarray} />
                </div>
              )}
            </div>
            {/* </form> */}

            {/* <div className="card card1 custom-card shadow p-2 bg-body rounded">
                            <div className="card-body">
                                <h5 className="card-header text-center h2">Global Form</h5>

                            </div>
                        </div> */}
          </div>
          <div className="col-md-4">
            <div className="row">
              {/* {console.log(isLoading)} */}
              {isLoading ? ( // Check loading state
                <div className="col-12 text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <p>Loading images...</p>
                </div>
              ) : (
                Object.entries(images).map(([imgType, imgData]) => (
                  <div
                    className="col-md-6 position-relative"
                    key={imgType}
                    style={
                      imgData.url && imgType != "invoiceImage"
                        ? {}
                        : { display: "none" }
                    }
                  >
                    <div className="mb-3">
                      <label className="form-label">
                        {imgType === "invoiceImage"
                          ? "Product Invoice / Bill Image"
                          : imgType === "frontImage"
                          ? "Product Front Image"
                          : imgType === "backImage"
                          ? "Product Back Image"
                          : imgType === "leftImage"
                          ? "Product Left Image"
                          : "Product Right Side Image"}
                        <span> *</span>
                      </label>
                      <div className=" mb-3">
                        {imgData.url ? (
                          <>
                            <InnerImageZoom
                              src={imgData.url}
                              zoomSrc={imgData.url}
                              alt={imgType.replace(/([A-Z])/g, " $1").trim()}
                              width="250px"
                              height="250"
                              // hasSpacer={true}
                            />
                            <Link
                              className="btn btn-success downloadbtn position-absolute"
                              style={{
                                right: "12%",
                                top: "12%",
                                opacity: "0.9",
                              }}
                              to={imgData.downloadUrl}
                              // href={imgData.downloadUrl.split('/').pop()} // Should be the original HEIC AWS URL
                              // download={imgData.downloadUrl.split('/').pop()} // Extracts filename for proper download
                            >
                              {/* <i className="fa download"></i> */}
                              <i className="bi bi-download"></i>
                            </Link>
                          </>
                        ) : (
                          <p className="text-danger">Image not available</p>
                        )}
                      </div>
                      {errors[imgType] && (
                        <span className="error-message text-danger">
                          {errors[imgType]}
                        </span>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div class="card row mt-4">
              <div className="card-head p-3">
                <h2>QC Request</h2>
              </div>
              <div className="card-body">
                <form id="NPIdetail" method="POST" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Product Name<span> *</span>
                    </label>
                    <input
                      type="text"
                      className="form-control capital"
                      id="name"
                      placeholder="Enter Your Product Name"
                      value={formData.name}
                      onChange={handleChange}
                      // readOnly={isReadOnly}
                    />
                    {errors.name && (
                      <span className="error-message text-danger">
                        {errors.name}
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <Autocomplete
                      url={`${serverUrl}/api/autocomplete/storage`}
                      value={formData.placementform}
                      onChange={handleChange}
                      label="Placement Form"
                      id="placementform"
                    />
                    {errors.pack && (
                      <span className="error-message text-danger">
                        {errors.placementform}
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <Autocomplete
                      url={`${serverUrl}/api/autocomplete/storage-care`}
                      value={formData.storagecare}
                      onChange={handleChange}
                      label="Storage Care"
                      id="storagecare"
                    />
                    {errors.pack && (
                      <span className="error-message text-danger">
                        {errors.storagecare}
                      </span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="universal_key" className="form-label">
                      UNIVERSAL NO
                    </label>
                    <h1 className="text-center">{formData.universal_key}</h1>
                    <input
                      type="hidden"
                      className="form-control capital"
                      id="universal_key"
                      placeholder="Enter a Note or NA"
                      value={formData.universal_key}
                      readOnly
                      //onChange={handleChange}
                    />
                    {errors.note && (
                      <span className="error-message text-danger">
                        {errors.note}
                      </span>
                    )}
                  </div>
                  <input type="hidden" name="universal_detail" />
                  {request.QcStatus === "Pending" && (
                    <button
                      className="btn btn-primary"
                      style={{ width: "120px" }}
                    >
                      Submit
                    </button>
                  )}
                  {/* <button
                    className="btn btn-primary"
                    style={{ width: "120px" }}
                  >
                    Submit
                  </button> */}
                </form>
              </div>
              <div className="card-footer"></div>
            </div>
          </div>
        </div>
        <div className="row d-none">
          <div className="card p-4">
            <h3>Images</h3>
            <div className="row">
              {/* {console.log(isLoading)} */}
              {isLoading ? ( // Check loading state
                <div className="col-12 text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <p>Loading images...</p>
                </div>
              ) : (
                Object.entries(images).map(([imgType, imgData]) => (
                  <div
                    className="col-md-3"
                    key={imgType}
                    style={imgData.url ? {} : { display: "none" }}
                  >
                    <div className="mb-3">
                      <label className="form-label">
                        {imgType === "invoiceImage"
                          ? "Product Invoice / Bill Image"
                          : imgType === "frontImage"
                          ? "Product Front Image"
                          : imgType === "backImage"
                          ? "Product Back Image"
                          : imgType === "leftImage"
                          ? "Product Left Image"
                          : "Product Right Side Image"}
                        <span> *</span>
                      </label>
                      <div>
                        {imgData.url ? (
                          <>
                            <InnerImageZoom
                              src={imgData.url}
                              zoomSrc={imgData.url}
                              alt={imgType.replace(/([A-Z])/g, " $1").trim()}
                              width="100%"
                            />
                            <Link
                              className="btn btn-success downloadbtn"
                              to={imgData.downloadUrl}
                              // href={imgData.downloadUrl.split('/').pop()} // Should be the original HEIC AWS URL
                              // download={imgData.downloadUrl.split('/').pop()} // Extracts filename for proper download
                            >
                              Download
                            </Link>
                          </>
                        ) : (
                          <p className="text-danger">Image not available</p>
                        )}
                      </div>
                      {errors[imgType] && (
                        <span className="error-message text-danger">
                          {errors[imgType]}
                        </span>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const Row = ({ name, value }) => {
  const [expanded, setExpanded] = useState(false);

  // Check if the value is an object or array
  // console.log(name);
  // console.log(typeof value);
  if (name == "category") {
    value = JSON.parse(value);
  } else if (name == "main_category") {
    if (value == "1") {
      value = "surgical";
    } else if (value == "2") {
      value = "medical";
    } else if (value == "3" || value == "4") {
      value = "FMCG";
    } else if (value == "5") {
      value = "OPRS";
    }
  } else if (name == "molecule") {
    try {
      value = JSON.parse(value); // Try parsing JSON
      if (Array.isArray(value) && value.length > 0) {
        value = value; // Get first element if it's an array
      } else {
        console.warn("Unexpected molecule data format:", value);
      }
    } catch (error) {
      console.error("Error parsing molecule JSON:", error);
      value = value; // Keep original value if parsing fails
    }
  }
  const isNested = typeof value === "object" && value !== null;

  return (
    <>
      <tr>
        <td className="border p-2">
          {isNested && (
            <button
              onClick={() => setExpanded(!expanded)}
              className="mr-2 bg-gray-300 px-2 rounded"
            >
              {expanded ? "▼" : "▶"}
            </button>
          )}
          {formatKey(name)}
        </td>
        <td className="border p-2">
          {isNested ? "" : formatValue(value)}
          {/* {isNested ? (Array.isArray(value) ? "Array" : "Object") : formatValue(value)} */}
          {/* {isNested ? "Object / Array" : value} */}
        </td>
      </tr>
      {expanded && isNested && (
        <tr>
          <td colSpan={2} className="border p-2 bg-gray-100">
            <Table
              data={Object.entries(value).map(([key, val]) => ({
                name: key,
                value: val,
              }))}
            />
          </td>
        </tr>
      )}
    </>
  );
};
const formatKey = (key) => {
  if (!isNaN(key)) return "Moledule_" + (parseInt(key) + 1);
  // if (key.startsWith("_index")) return ""; // Hide indexes
  return key.replace(/_/g, " ").toUpperCase();
};

const formatValue = (value) => {
  // console.log("format value",value)
  if (typeof value === "string" && value.includes("|")) {
    const [code, label] = value.split("|");
    return `${label} (${code})`; // Convert "MO0922|PARACETAMOL" -> "PARACETAMOL (MO0922)"
  }
  return value;
};
const Table = ({ data }) => {
  return (
    <table className="border-collapse border w-full">
      <thead>
        <tr>
          <th className="border p-2">Field</th>
          <th className="border p-2">Value</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <Row key={index} name={item.name} value={item.value} />
        ))}
      </tbody>
    </table>
  );
};

export default QcProductDetails;
