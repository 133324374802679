import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faHourglass,
  faTimes,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/AdminCards.css";
import { useSession } from "./../Common/SessionContext";

const AdminCards = ({ onCardClick }) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const { sessionData } = useSession();
  const [counts, setCounts] = useState({
    total: 0,
    pending: 0,
    rejected: 0,
    approved: 0,
    verified: 0,
    Done: 0,
    Pending: 0,
  });

  const [avgTimeDifferences, setAvgTimeDifferences] = useState({
    pending: "",
    rejected: "",
    approved: "",
    verified: "",
    Done: "",
    Pending: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch counts
        const countResponse = await axios.get(`${serverUrl}/api/count`);
        setCounts(countResponse.data);

        console.log("countResponse-->", countResponse);

        // Fetch average time differences
        const requestStatuses = [
          "pending",
          "rejected",
          "approved",
          "verified",
          "Done",
          "Pending",
        ];
        const avgTimeDiffs = {};

        for (const status of requestStatuses) {
          const response = await axios.get(
            `${serverUrl}/api/request?status=${status}`
          );
          avgTimeDiffs[status] = response.data.avgTimeDifference;
        }

        setAvgTimeDifferences(avgTimeDiffs);

        console.log("Avg Diff-->", avgTimeDifferences);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    fetchData();
  }, []);

  const renderCard = (title, icon, count, color, status, avgTimeDiff) => {
    return (
      <div
        className="col-lg-3 col-md-6 mb-4"
        onClick={() => onCardClick(status)}
        style={{ cursor: "pointer" }}
      >
        <div className={`card text-white h-100 ${color}`}>
          <div className="card-body d-flex justify-content-between align-items-center">
            <div>
              <h5 className="card-title dash-title text-white">{title}</h5>
              <h3 className="fw-bold fs-2">{count}</h3>
              {avgTimeDiff && (
                <p className="avg-time-difference">{avgTimeDiff}</p>
              )}
            </div>
            <FontAwesomeIcon icon={icon} className="dash-icon" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="pt-2">
      <h3>{sessionData.userRole.toUpperCase()} Workbench</h3>
      <hr />
      <div className="row">
        {sessionData.userRole != "qc" &&
          renderCard(
            "Total Requests",
            faList,
            counts.total,
            "total-card",
            "total",
            ""
          )}
        {sessionData.userRole != "qc" &&
          renderCard(
            "Pending & Verified",
            faHourglass,
            `${counts.pending} | ${counts.verified}`,
            "pending-card",
            "pending_verified",
            `${avgTimeDifferences.pending} | ${avgTimeDifferences.verified}`
          )}
        {sessionData.userRole != "qc" &&
          renderCard(
            "Approved Requests",
            faCheck,
            counts.approved,
            "approved-card",
            "approved",
            avgTimeDifferences.approved
          )}
        {sessionData.userRole != "qc" &&
          renderCard(
            "Rejected Requests",
            faTimes,
            counts.rejected,
            "rejected-card",
            "rejected",
            avgTimeDifferences.rejected
          )}

        {sessionData.userRole == "qc" &&
          renderCard(
            "Pending ",
            faHourglass,
            counts.pendingQc,
            "pending-card",
            "Pending",
            avgTimeDifferences.Pending
          )}

        {sessionData.userRole == "qc" &&
          renderCard(
            "QC Done",
            faCheck,
            counts.doneQc,
            "approved-card",
            "Done",
            avgTimeDifferences.Done
          )}
      </div>
    </div>
  );
};

export default AdminCards;
