import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate,useLocation} from "react-router-dom";

// admin route components
import UserList from "./components/Admin/Userlist";
import UserForm from "./components/Admin/Userform";
import List from "./components/Admin/List";
import Dashboard from "./components/Admin/Dashboard";
import ProductDetails from "./components/Admin/ProductDetails";
import QcProductDetails from "./components/Admin/QcProductDetails";

// user route components
import Global from "./components/User/Global";
import ReqList from "./components/User/ReqList";
import StoreForm from "./components/User/StoreForm";
import UserDashboard from "./components/User/UserDashboard";

// common route components
import LoginPage from "./components/Common/LoginPage";
import Logout from "./components/Common/Logout";
import NotFound from "./components/Common/NotFound";
import ForbiddenPage from "./components/Common/ForbiddenPage";

// layout components
import UserLayout from './components/Common/UserLayout';
import AdminLayout from './components/Common/AdminLayout';
import PublicLayout from './components/Common/PublicLayout';

// context provider
import { SessionProvider } from './components/Common/SessionContext';


function App() {
  const [userRole, setuserRole] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  console.log(userRole);
  useEffect(() => {
    console.log(userRole);
    if (userRole === 'admin' && location.pathname.startsWith(userRole)) {
      navigate(location.pathname);
    } else if (userRole === 'superadmin' && location.pathname.startsWith(userRole)) {
      // navigate('/superadmin/list');
      navigate(location.pathname);
    } else if (userRole === 'qc' && location.pathname.startsWith(userRole)) {
      // navigate('/user');
      navigate(location.pathname);

    } else if (userRole === 'spoc' && location.pathname.startsWith(userRole)) {
      navigate(location.pathname);
    } else if (userRole === 'user') {
      // navigate('/user');
      navigate(location.pathname);
    } 
  }, [userRole, navigate]);

  return (
    <SessionProvider>
      <div className="App">
        <Routes>
          <Route element={<PublicLayout />}>
            <Route path="/" element={<LoginPage setuserRole={setuserRole} />} />
            <Route path="/login" element={<LoginPage setuserRole={setuserRole} />} />
            <Route path="/logout" element={<Logout setuserRole={setuserRole} />} />
            <Route path="*" element={<NotFound />} />
            <Route path="common/forbidden" element={<ForbiddenPage />} />
          </Route>

          {/* User Routes */}
          <Route path="/user" element={userRole === "user" ? <UserLayout /> : <Navigate to="/login" />}>
            <Route index element={<UserDashboard />} />
            <Route path="dashboard" element={<UserDashboard />} />
            <Route path="storeform" element={<StoreForm />} />
            <Route path="request-list" element={<ReqList />} />
            <Route path="request-details/:id" element={<Global />} />
          </Route>

          {/* Admin Routes */}
          <Route path="/admin" element={userRole === "admin" ? <AdminLayout /> : <Navigate to="/login" />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="userlist" element={<UserList />} />
            <Route path="userform" element={<UserForm />} />
            <Route path="list" element={<List />} />
            <Route path="product/:id" element={<ProductDetails />} />
          </Route>
          {/* Admin Routes */}
          <Route path="/qc" element={userRole === "qc" ? <AdminLayout /> : <Navigate to="/login" />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="userlist" element={<UserList />} />
            <Route path="userform" element={<UserForm />} />
            <Route path="list" element={<List />} />
            <Route path="product/:id" element={<QcProductDetails />} />
          </Route>
           {/* <Route path="/superadmin" element={userRole === "superadmin" ? <AdminLayout /> : <Navigate to="/login" />}> */}
           <Route path="/superadmin" element={userRole === "superadmin" ? <AdminLayout /> : <Navigate to="/login" />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="list" element={<List />} />
            <Route path="product/:id" element={<ProductDetails />} /> 
            <Route path="userform" element={<UserForm />} />
            <Route path="userlist" element={<UserList />} />
          </Route>

          {/* SPOC Routes */}
          {/* <Route path="/spoc" element={userRole === "superadmin" ? <AdminLayout /> : <Navigate to="/login" />}> */}
          <Route path="/spoc" element={userRole === "spoc" ? <AdminLayout /> : <Navigate to="/login" />}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="list" element={<List />} />
            <Route path="product/:id" element={<ProductDetails />} />
          </Route>
          
        </Routes>
      </div>
    </SessionProvider>
  );
}

export default App;
