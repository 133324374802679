import React, { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faHourglassHalf,
  faEye,
  faM,
  faD,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Navbar from "../Common/Navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../styles/UserReqList.css";
import BottomNav from "../Common/BottomNav";
import { css } from "@emotion/react";
import { FadeLoader } from "react-spinners";

const StoreRequestsTable = () => {
  const [storeRequests, setStoreRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [requestsPerPage] = useState(10); // Display 10 items per page
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchStoreRequests = async () => {
      try {
        const response = await axios.get(`${serverUrl}/api/user/request-list`);
        console.log(response);
        setStoreRequests(response.data);
        setFilteredRequests(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching store requests:", error);
        swal({
          title: "Error!",
          text: "Failed to fetch store requests. Please try again later.",
          icon: "error",
          button: "OK",
        });
        setLoading(false);
      }
    };
    fetchStoreRequests();
  }, []);

  useEffect(() => {
    const filtered = storeRequests.filter((request) => {
      const matchesSearch =
        request.req_id.toLowerCase().includes(search.toLowerCase()) ||
        // request.store_contact_name.toLowerCase().includes(search.toLowerCase()) ||
        request.product_name.toLowerCase().includes(search.toLowerCase());
      // request.category_person.toLowerCase().includes(search.toLowerCase()) ||
      // request.status.toLowerCase().includes(search.toLowerCase()) ||
      // request.item_code.includes(search.toLowerCase());

      const matchesDateRange =
        (!startDate ||
          moment(request.created_at).isSameOrAfter(startDate, "day")) &&
        (!endDate || moment(request.created_at).isSameOrBefore(endDate, "day"));

      return matchesSearch && matchesDateRange;
    });
    setFilteredRequests(filtered);
  }, [search, startDate, endDate, storeRequests]);

  // const handleViewClick = async (row) => {
  //   try {
  //     const response = await axios.get(`${serverUrl}/api/user/request-details/${row.req_id}`);
  //     console.log(response);
  //     navigate(`/user/request-details/${row.req_id}`, { state: { request: response.data } });
  //   } catch (error) {
  //     console.error('Error fetching request details:', error);
  //     swal({
  //       title: "Error!",
  //       text: "Failed to fetch request details. Please LOGIN !!",
  //       icon: "error",
  //       button: "OK",
  //     });
  //   }
  // };

  const handleCardClick = (status) => {
    if (status) {
      const filtered = storeRequests.filter(
        (request) =>
          request.status === status ||
          (status === "Pending" && request.status === "Verified")
      );
      setFilteredRequests(filtered);
    } else {
      setFilteredRequests(storeRequests);
    }
    setCurrentPage(1);
  };

  const renderDataTableCards = () => {
    const indexOfLastRequest = currentPage * requestsPerPage;
    const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
    const currentRequests = filteredRequests.slice(
      indexOfFirstRequest,
      indexOfLastRequest
    );

    return currentRequests.map((request, index) => {
      const generatedAt = moment.utc(request.created_at);
      const approvedAt = moment.utc(request.updated_at);
      const duration = moment.duration(approvedAt.diff(generatedAt));

      let timeDifference;

      if (duration.asHours() >= 24) {
        const days = Math.floor(duration.asDays());
        timeDifference = `${days} days`;
        // Use timeDifference as needed
      } else {
        const hours = Math.floor(duration.asHours())
          .toString()
          .padStart(2, "0");
        const minutes = duration.minutes().toString().padStart(2, "0");
        // timeDifference = `${hours}:${minutes}`;
        timeDifference = `${hours} hrs ${minutes} min`;
        // Use timeDifference as needed
      }

      let cardBgColor = "";
      if (request.status === "Approved") {
        cardBgColor = "card-approved";
      } else if (
        request.status === "Pending" ||
        request.status === "Verified"
      ) {
        cardBgColor = "card-pending";
      } else {
        cardBgColor = "card-rejected";
      }

      const statusIcon =
        request.status === "Pending"
          ? "Verified"
          : request.status === "Verified"
          ? "Created"
          : "";

      return (
        <div key={index} className="col-md-12">
          {/* <div className={`card ${cardBgColor}`} onClick={() => handleViewClick(request)} style={{ cursor: 'pointer' }}> */}
          <div className={`card ${cardBgColor}`} style={{ cursor: "pointer" }}>
            <div
              className="card-header req-header d-flex justify-content-between align-items-center"
              style={{ backgroundColor: cardBgColor }}
            >
              <h5 className="card-title">
                {request.product_name}
                {request.item_code && (
                  <span style={{ color: "red" }}> ({request.item_code})</span>
                )}
              </h5>
              <span className="badge text-bg-secondary">
                {request.category_name}
              </span>
            </div>

            <div className="card-body req-card d-flex justify-content-between align-items-center">
              <div className="">
                <p
                  className="card-text"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {request.req_id}
                  {statusIcon && (
                    <span
                      style={{
                        display: "flex",
                        width: "fit-content",
                        height: "30px",
                        borderRadius: "6px",
                        backgroundColor: " rgb(196, 140, 218)",
                        marginLeft: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {statusIcon}
                      {/* <FontAwesomeIcon
                        icon={statusIcon}
                        style={{ marginRight: "0px" }}
                      /> */}
                    </span>
                  )}
                </p>
                <p className="card-text">
                  {moment(request.created_at).format("DD-MM-YYYY h:mm a")}
                </p>
              </div>

              <div className="text-center">
                <h6 className="card-time-difference">{timeDifference}</h6>
              </div>
              <div className="text-center req-icon">
                {request.status === "Approved" && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="card-icon"
                    style={{ fontSize: "3rem" }}
                  />
                )}
                {(request.status === "Pending" ||
                  request.status === "Verified") && (
                  <FontAwesomeIcon
                    icon={faHourglassHalf}
                    className="card-icon"
                    style={{ fontSize: "3rem" }}
                  />
                )}
                {request.status === "Rejected" && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="card-icon"
                    style={{ fontSize: "3rem" }}
                  />
                )}
              </div>
            </div>
            <div
              className="card-footer req-footer"
              style={{ backgroundColor: cardBgColor }}
            >
              {request.status === "Rejected" && (
                <p className="card-text text-danger">
                  Reason: {request.product_information.rejectionReason}
                </p>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredRequests.length / requestsPerPage);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  if (loading) {
    return (
      <div
        className="sweet-loading"
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FadeLoader
          color={"#123abc"}
          loading={loading}
          css={override}
          height={15}
        />
      </div>
    );
  }

  const renderCard = (title, icon, count, color, status) => {
    const filteredRequests = storeRequests.filter(
      (request) => request.status === status
    );

    let totalDuration = moment.duration(0);
    filteredRequests.forEach((request) => {
      const createdAt = moment(request.created_at);
      const now = moment();
      const duration = moment.duration(now.diff(createdAt));
      totalDuration.add(duration);
    });

    const avgDuration =
      filteredRequests.length > 0
        ? totalDuration.asMilliseconds() / filteredRequests.length
        : 0;
    const avgDurationMoment = moment.duration(avgDuration);
    const hours = Math.floor(avgDurationMoment.asHours())
      .toString()
      .padStart(2, "0");
    const minutes = avgDurationMoment.minutes().toString().padStart(2, "0");
    // const avgTimeDifference = `${hours}:${minutes}`;
    const avgTimeDifference = `${hours} HRS`;

    return (
      <div
        className="col-3 mb-3"
        onClick={() => handleCardClick(status)}
        style={{ cursor: "pointer", paddingRight: "8px", paddingLeft: "8px" }}
      >
        <div className="summary-card card" style={{ backgroundColor: color }}>
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5 className="summary-card-title">{count}</h5>
                <p className="summary-card-text">{title}</p>
              </div>
              <div className="text-center position-relative">
                <FontAwesomeIcon icon={icon} className="summary-card-icon" />
                {status !== "" && (
                  <p className="summary-card-timestamp">{avgTimeDifference}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  <div className="row mt-3">
    {renderCard("Total", faEye, storeRequests.length, "#0D5FDA", "")}
    {renderCard(
      "Pending",
      faHourglassHalf,
      storeRequests.filter((request) => request.status === "Pending").length,
      "#D89022",
      "Pending"
    )}
    {renderCard(
      "Cancelled",
      faTimesCircle,
      storeRequests.filter((request) => request.status === "Rejected").length,
      "#CD4216",
      "Rejected"
    )}
    {renderCard(
      "Completed",
      faCheckCircle,
      storeRequests.filter((request) => request.status === "Approved").length,
      "#9AFB97",
      "Approved"
    )}
  </div>;

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (startPage > 1) {
      pageNumbers.push(
        <li
          key={1}
          className={`page-item ${currentPage === 1 ? "active" : ""}`}
        >
          <button onClick={() => setCurrentPage(1)} className="page-link">
            1
          </button>
        </li>
      );
      if (startPage > 2) {
        pageNumbers.push(
          <li key="ellipsis-start" className="page-item">
            <span className="page-link">...</span>
          </li>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button onClick={() => setCurrentPage(i)} className="page-link">
            {i}
          </button>
        </li>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(
          <li key="ellipsis-end" className="page-item">
            <span className="page-link">...</span>
          </li>
        );
      }
      pageNumbers.push(
        <li
          key={totalPages}
          className={`page-item ${currentPage === totalPages ? "active" : ""}`}
        >
          <button
            onClick={() => setCurrentPage(totalPages)}
            className="page-link"
          >
            {totalPages}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      <Navbar />
      <div className="container mt-4" style={{ marginBottom: "70px" }}>
        <div className="row mt-3">
          {renderCard("Total", faEye, storeRequests.length, "#0D5FDA", "")}
          {renderCard(
            "Pending",
            faHourglassHalf,
            storeRequests.filter(
              (request) =>
                request.status === "Pending" || request.status === "Verified"
            ).length,
            "#D89022",
            "Pending"
          )}
          {renderCard(
            "Cancelled",
            faTimesCircle,
            storeRequests.filter((request) => request.status === "Rejected")
              .length,
            "#CD4216",
            "Rejected"
          )}
          {renderCard(
            "Completed",
            faCheckCircle,
            storeRequests.filter((request) => request.status === "Approved")
              .length,
            "#9AFB97",
            "Approved"
          )}
        </div>
        <div className="row mb-4 justify-content-center">
          <div className="col-md-6">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Request ID, Product Name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/* <div className="col-md-3">
            <DatePicker
              className="form-control"
              selected={startDate}
              onChange={date => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Start Date"
            />
          </div>
          <div className="col-md-3">
            <DatePicker
              className="form-control"
              selected={endDate}
              onChange={date => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="End Date"
            />
          </div> */}
        </div>

        <div className="mb-1 ms-1">Request List</div>

        <div className="row">{renderDataTableCards()}</div>
        <div className="d-flex justify-content-center mb-4">
          {/* <button
            className="btn btn-primary me-2"
            // style={{ backgroundColor: '#004400', border: 'none' }}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`btn btn-outline-primary me-2 ${currentPage === index + 1 ? 'active' : ''}`}
            // style={{ borderColor: '#004400', color: '#004400' }}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="btn btn-primary"
            // style={{ backgroundColor: '#004400', border: 'none' }}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button> */}
          <nav>
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button className="page-link" onClick={handlePreviousPage}>
                  Previous
                </button>
              </li>
              {renderPageNumbers()}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button className="page-link" onClick={handleNextPage}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <BottomNav />
    </div>
  );
};

export default StoreRequestsTable;
