import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  faList,
  faUsers,
  faSignOut,
  faBuilding, // Placeholder for brand icon
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./../../styles/Sidebar.css";
import { useSession } from "./../Common/SessionContext";

const Sidebar = () => {
  const { sessionData } = useSession();
  const [error, setError] = useState(null);
  const serverUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const handleLogout = async () => {
    localStorage.removeItem("branchCode");
    localStorage.removeItem("branchName");
    localStorage.removeItem("roleName");
    try {
      const response = await axios.post(`${serverUrl}"/api/auth/logout`);
      if (response.status === 200) {
        navigate("/");
      } else {
        setError("Logout failed. Please try again.");
      }
    } catch (error) {
      console.error("Error logging out:", error);
      setError("An error occurred during logout. Please try again.");
    }
  };

  return (
    <div className="sidebar thin-sidebar">
      {/* Brand Icon */}
      <div className="brand-icon text-light">
        {/* <FontAwesomeIcon icon={faBuilding} className="fa-icon" /> */}
        <img
          src="/images/wellness_logo.png"
          className="img-fluid"
          alt="Logo"
        />
      </div>

      <ul>
        <li className="text-light">
          <Link to={`/${sessionData.userRole}/list`}>
            <FontAwesomeIcon icon={faList} className="fa-icon" />
          </Link>
        </li>
        
        {sessionData.userRole === "superadmin" && (
          <li className="text-light">
            <li className="text-light">
            <Link to={`/${sessionData.userRole}/userform`}>
              <FontAwesomeIcon icon={faUsers} className="fa-icon" />
            </Link>
          </li>
            <Link to={`/${sessionData.userRole}/userlist`}>
              <FontAwesomeIcon icon={faUsers} className="fa-icon" />
            </Link>
          </li>
        )}

        {/* Logout Icon */}
        <li
          className="text-light"
          style={{ position: "fixed", bottom: "20px", marginLeft: "8px" }}
        >
          <Link to="/logout" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOut} className="fa-icon" />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
